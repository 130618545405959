import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Fna from './Fna';
//import Msc from './Msc';
//import Msc2021 from './Msc2021';
import Csda from './Csda';
//import Ihca from './Ihca';
import Ihcaals2021 from './Ihca-als2021';
//import Coa from './Coa';
import Vcd from './Vcd';
//import Common from './Common';
import Paos from './Paos';
import Goma from './Goma';
import Commonpowerup2021 from './Common-powerup2021';
import * as serviceWorker from './serviceWorker';
import './config';
import Hfam from './Hfam';
import Focus2022 from './Focus2022';
import Fna2022 from './Fna2022';
import Security22 from './Security22';
import Navigate22 from './Navigate22';
import Tobi2022 from './Tobi2022';
import MdAPA from './MdAPA';
import MdAPA2023 from './MdAPA2023';
import MdAPA2024 from './MdAPA2024';

var pathArray = window.location.pathname.split('/');



  var eventId = localStorage.getItem('eventId');
  if(pathArray[2] === 'login' && pathArray.length > 2){
    eventId = pathArray[3];
    localStorage.setItem('eventId',eventId);
    localStorage.setItem('eventName',pathArray[1]);
  }

if(pathArray[1] !== localStorage.getItem('eventName') && pathArray[1] !== 'chat' && pathArray[1] !== 'video-conference' && pathArray[1] !== 'video-call' && pathArray[1] !== 'conference-meeting' && pathArray[1] !== '' ){
  localStorage.clear();
  localStorage.setItem('eventId',eventId);
  localStorage.setItem('eventName',pathArray[1]);
}

if(pathArray[1] === 'fna'){
  localStorage.setItem('eventId',556);// I did it so null does not come in login url
  localStorage.setItem('eventName','fna')
  ReactDOM.render(
    <React.StrictMode>
      <Fna />
    </React.StrictMode>,
    document.getElementById('root')
  );
}
// else if(pathArray[1] === 'msc'){
//   localStorage.setItem('eventId',524);// I did it so null does not come in login url
//   localStorage.setItem('eventName','msc')
//   ReactDOM.render(
//     <React.StrictMode>
//       <Msc />
//     </React.StrictMode>,
//     document.getElementById('root')
//   );
// }
// else if(pathArray[1] === 'msc2021'){
//   localStorage.setItem('eventId',632);// I did it so null does not come in login url
//   localStorage.setItem('eventName','msc2021')
//   ReactDOM.render(
//     <React.StrictMode>
//       <Msc2021 />
//     </React.StrictMode>,
//     document.getElementById('root')
//   );
// }
else if(pathArray[1] === 'csda'){
  if(pathArray[2] !== 'login')window.location.replace("/csda/login/605");// Due to this line, no one can access this event anymore.
  localStorage.setItem('eventId',605);// I did it so null does not come in login url
  localStorage.setItem('eventName','csda')
  ReactDOM.render(
    <React.StrictMode>
      <Csda />
    </React.StrictMode>,
    document.getElementById('root')
  );
}
// else if(pathArray[1] === 'ihca'){
//   localStorage.setItem('eventId',607);// I did it so null does not come in login url
//   localStorage.setItem('eventName','ihca')
//   ReactDOM.render(
//     <React.StrictMode>
//       <Ihca />
//     </React.StrictMode>,
//     document.getElementById('root')
//   );
// }
// else if(pathArray[1] === 'coa'){
//   localStorage.setItem('eventId',629);// I did it so null does not come in login url  California Orthopedic Association
//   localStorage.setItem('eventName','coa')
//   ReactDOM.render(
//     <React.StrictMode>
//       <Coa />
//     </React.StrictMode>,
//     document.getElementById('root')
//   );
// }
else if(pathArray[1] === 'paos'){
  if(pathArray[2] !== 'login')window.location.replace("/paos/login/631");// Due to this line, no one can access this event anymore.
  localStorage.setItem('eventId',631);// I did it so null does not come in login url  PAOS
  localStorage.setItem('eventName','paos')
  ReactDOM.render(
    <React.StrictMode>
      <Paos />
    </React.StrictMode>,
    document.getElementById('root')
  );
}
else if(pathArray[1] === 'vcd'){
  localStorage.setItem('eventId',590);// I did it so null does not come in login url
  localStorage.setItem('eventName','vcd')
  ReactDOM.render(
    <React.StrictMode>
      <Vcd />
    </React.StrictMode>,
    document.getElementById('root')
  );
}
// else if(pathArray[1] === 'common'){
//   localStorage.setItem('eventId',626);// I did it so null does not come in login url
//   localStorage.setItem('eventName','common')
//   ReactDOM.render(
//     <React.StrictMode>
//       <Common />
//     </React.StrictMode>,
//     document.getElementById('root')
//   );
// }
else if(pathArray[1] === 'common-powerup2021'){
  localStorage.setItem('eventId',674);// I did it so null does not come in login url
  localStorage.setItem('eventName','common-powerup2021')
  ReactDOM.render(
    <React.StrictMode>
      <Commonpowerup2021 />
    </React.StrictMode>,
    document.getElementById('root')
  );
}
else if(pathArray[1] === 'hfam'){
  localStorage.setItem('eventId',696);// I did it so null does not come in login url
  localStorage.setItem('eventName','hfam')
  ReactDOM.render(
    <React.StrictMode>
      <Hfam />
    </React.StrictMode>,
    document.getElementById('root')
  );
}
else if(pathArray[1] === 'goma'){
  localStorage.setItem('eventId',697);// I did it so null does not come in login url
  localStorage.setItem('eventName','goma')
  ReactDOM.render(
    <React.StrictMode>
      <Goma />
    </React.StrictMode>,
    document.getElementById('root')
  );
}
else if(pathArray[1] === 'ihca-als2021'){
    localStorage.setItem('eventId',706);// I did it so null does not come in login url
    localStorage.setItem('eventName','ihca-als2021')
    ReactDOM.render(
      <React.StrictMode>
        <Ihcaals2021 />
      </React.StrictMode>,
      document.getElementById('root')
    );
  }
else if(pathArray[1] === 'focus2022'){
    localStorage.setItem('eventId',709);// I did it so null does not come in login url
    localStorage.setItem('eventName','focus2022')
    ReactDOM.render(
      <React.StrictMode>
        <Focus2022 />
      </React.StrictMode>,
      document.getElementById('root')
    );
  }
  else if(pathArray[1] === 'navigate2022'){
    localStorage.setItem('eventId',846);// I did it so null does not come in login url
    localStorage.setItem('eventName','navigate2022')
    ReactDOM.render(
      <React.StrictMode>
        <Navigate22 />
      </React.StrictMode>,
      document.getElementById('root')
    );
  }
  else if(pathArray[1] === 'fna2022'){
    localStorage.setItem('eventId',713);// I did it so null does not come in login url
    localStorage.setItem('eventName','fna2022')
    ReactDOM.render(
      <React.StrictMode>
        <Fna2022 />
      </React.StrictMode>,
      document.getElementById('root')
    );
  }
  else if(pathArray[1] === 'security22'){
    localStorage.setItem('eventId',826);// I did it so null does not come in login url
    localStorage.setItem('eventName','security22')
    ReactDOM.render(
      <React.StrictMode>
        <Security22 />
      </React.StrictMode>,
      document.getElementById('root')
    );
  }
  else if(pathArray[1] === 'tobi2022'){
    localStorage.setItem('eventId',837);
    localStorage.setItem('eventName','tobi2022')
    ReactDOM.render(
      <React.StrictMode>
        <Tobi2022 />
      </React.StrictMode>,
      document.getElementById('root')
    );
  }
  // else if(pathArray[1] === 'mdapa'){
  //   localStorage.setItem('eventId',854);
  //   localStorage.setItem('eventName','mdapa')
  //   ReactDOM.render(
  //     <React.StrictMode>
  //       <MdAPA />
  //     </React.StrictMode>,
  //     document.getElementById('root')
  //   );
  // } 
  else if(pathArray[1] === 'mdapa2024'){
    localStorage.setItem('eventId',1131);
    localStorage.setItem('eventName','mdapa2024')
    ReactDOM.render(
      <React.StrictMode>
        <MdAPA2024 />
      </React.StrictMode>,
      document.getElementById('root')
    );
  }
  else if(pathArray[1] === 'mdapa2023'){
    localStorage.setItem('eventId',1009);
    localStorage.setItem('eventName','mdapa2023')
    ReactDOM.render(
      <React.StrictMode>
        <MdAPA2023 />
      </React.StrictMode>,
      document.getElementById('root')
    );
  }
else{
  //localStorage.setItem('eventName','')
  if(pathArray[1] !== 'chat' && pathArray[1] !== 'video-conference' && pathArray[1] !== 'video-call' && pathArray[1] !== 'conference-meeting' && pathArray[1] !== '' ){
    window.location.href = window.location.protocol+'//'+window.location.host+'/fna/login/556';
  }
  
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
