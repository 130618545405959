import React,{useState} from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from './Components/MdAPA2024/Header';
import Login from './Components/MdAPA2024/Login';
import Lobby from './Components/MdAPA2024/Lobby';
import SpeakerList from './Components/MdAPA2024/speakers/SpeakerList';
import SpeakerDetails from './Components/MdAPA2024/speakers/SpeakerDetails';
import SponsorList from './Components/MdAPA2024/sponsors/Sponsors';
import SponsorDetails from './Components/MdAPA2024/sponsors/SponsorDetails';
import AttendeesList from './Components/MdAPA2024/attendee/Attendees';
import AttendeeDetails from './Components/MdAPA2024/attendee/AttendeeDetails';
import ExhibitHall from './Components/MdAPA2024/attendee/ExhibitHall';
import ExhibitorDetails from './Components/MdAPA2024/attendee/ExhibitorDetails';
import ConferenceSchedule from './Components/MdAPA2024/conference/getSession';
import Maps from './Components/MdAPA2024/Maps/Maps';
import MapDetail from './Components/MdAPA2024/Maps/MapDetail';
import UserProfile from './Components/MdAPA2024/UserProfile';
import LoginSplash from './Components/MdAPA2024/LoginSplash';
import ExhibitorProfile from './Components/MdAPA2024/Exhibitor/profile';
import SpeakerProfile from './Components/MdAPA2024/speakers/SpeakerProfile';
import ChatPage from "./TmChat/MdAPA2024/chat/ChatPage";
import ChatNotification from "./TmChat/MdAPA2024/chat/ChatNotification";

import PrivateRoute from './PrivateRoute';


const MdAPA2024 = () => {
    const [exhibitorToken,setExhibitorToken] = useState(localStorage.getItem('exhibitorToken') || null);

    return (
        <Router>
            <Header exhibitorToken={exhibitorToken} />
            {exhibitorToken?<ChatNotification />:''}
            <Switch>
                {/* <Route path="/" exact component={Login} /> */}
                <Route path="/mdapa2024/login/:id" exact render={(props) => <Login setExhibitorToken={setExhibitorToken} {...props}/>} />
                <PrivateRoute path="/mdapa2024/loginSplash" exact component={LoginSplash} />
                <PrivateRoute path="/mdapa2024/conference" exact component={ConferenceSchedule} />
                <PrivateRoute path="/mdapa2024/lobby" exact component={Lobby} />
                <PrivateRoute path="/mdapa2024/speakers" exact component={SpeakerList} />
                <PrivateRoute path="/mdapa2024/speakers/:id" exact component={SpeakerDetails} />
                <PrivateRoute path="/mdapa2024/sponsors" exact component={SponsorList} />
                <PrivateRoute path="/mdapa2024/sponsor/:id" exact component={SponsorDetails} />
                <PrivateRoute path="/mdapa2024/attendees" exact component={AttendeesList} />
                <PrivateRoute path="/mdapa2024/attendee/exhibitHall" exact component={ExhibitHall} />
                <PrivateRoute path="/mdapa2024/attendee/exhibitor/:exhibitorId/:title" exact component={ExhibitorDetails} />
                <PrivateRoute path="/mdapa2024/attendee/:emailId" exact component={AttendeeDetails} />
                <PrivateRoute path="/mdapa2024/maps" exact component={Maps} />
                <PrivateRoute path="/mdapa2024/maps/details" exact component={MapDetail} />
                <PrivateRoute path="/mdapa2024/user/profile" exact component={UserProfile} />
                <PrivateRoute path="/mdapa2024/exhibitor/profile" exact component={ExhibitorProfile} />
                <PrivateRoute path="/mdapa2024/speaker/profile" exact component={SpeakerProfile} />
                <PrivateRoute path="/mdapa2024/chat" exact component={ChatPage} />
                

            </Switch>
        </Router>
    )
}
export default MdAPA2024;